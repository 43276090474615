import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'H+',
    useCases: '',
    description: `H+ Is a smart home application me and my team have been concepting for a hardware client. The goal for the design was to make a one stop shop for all smart homes devices and allow the user to control them from a centralized application. Clean and simple design was our focus, not to over power the user with to many clicks off the homepage as well as labeling all icons to make navigation easy.`,
    comment: 'usecase coming soon - pending client approval',
  },
  caseId: 'h-plus',
  contents: ['image0.jpg'],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
